@import 'utilities/variables'

$material-design-icons-font-directory-path: '~material-design-icons-iconfont/dist/fonts/'
@import 'material-design-icons-iconfont/src/material-design-icons'

// Global style
html, body
    font-family: $bbraun-font-family
    color: $bbraun-non-clickable
    margin: 0

// Color styles
.red
    color: $bbraun-error

.green
    color: $bbraun-active

.violet
    color: $bbraun-accent

.container-mt
    margin-top: 75px

cx-table-with-pagination.home-table mat-row:has(mat-cell.disabled-row)
  background: $bbraun-gray-10
  cursor: not-allowed
  color: $bbraun-gray-60

cx-table-with-pagination.home-table mat-row.mat-mdc-row:has(mat-cell.disabled-row):not(.cx-selected-row):not(.cx-highlighted-row):hover
  background: $bbraun-gray-10 !important // due to the important in cortex styles

// override of mat-menu active item colors
button.mat-mdc-menu-item.menu-item-active
  background: rgba($bbraun-active, 0.08)
  color: $bbraun-active

  &:hover
    background: rgba($bbraun-active, 0.08) !important // important is needed due to mat-menu styles

.mat-mdc-menu-panel .mat-mdc-menu-content button.mat-mdc-menu-item.menu-item-active .mat-mdc-menu-item-text
  color: $bbraun-active !important // important is needed due to mat-menu styles
